import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { media } from "../../../../mq"

const MickeyTabsWrapper = styled.div`
max-width: 100%;
    .tabs-menu-inner-wrapper{
        position: relative;
        .tabs-menu{
            display: flex;
            justify-content: space-between;
            white-space: nowrap;
            overflow: scroll;
            &::-webkit-scrollbar{
                display: none;
            }
            .tabs-menu-item{
                background: none;
                color: inherit;
                border: none;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font: inherit;
                cursor: pointer;
                outline: inherit;
                &.active{
                    color: #f1592a; 
                }
            }
            div{
                position: relative;
                margin: 0 1.5rem 0 1.5rem;

            .tabs-menu-item-underline-mobile{
                    position: absolute;
                    bottom: 0;
                    display: block;
                    height: .25rem;
                    background-color:rgb(241, 89, 42);
                    transition-property: all;
                    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                    transition-duration: .3s;
                    ${media.smaller`display: none;`}

                }
            }
        }
        .tabs-menu-gray{
                position: absolute;
                bottom: 0;
                display: block;
                height: .25rem;
                background-color: rgb(242, 242, 242);
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: .3s;
                width: 100%;

            }
        .tabs-menu-item-underline{
            display: none;
            ${media.smaller`
                position: absolute;
                bottom: 0;
                display: block;
                height: .25rem;
                background-color:rgb(241, 89, 42);
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: .3s;
            `}

            }
    }
    .tabs-content{
        width: 100%;
        height: fit-content;
        min-height: 500px;
        margin-top: 40px
    }


    .ui.attached.tabular.menu{
    border: none !important;
    padding-bottom: 1rem;
    flex-direction: column;
    ${media.smaller`flex-direction: row;`}

    a.item{
      border-bottom: 3px solid #f2f2f2 !important;
      font-family: "Brown Bold";

    }
    a.active.item{
      border-bottom: 3px solid #f1592a !important;
      font-family: "Brown Bold";
      margin-bottom: 0;
    }
    a:first-child{
      display: flex;
      justify-content: center;
      flex: 0 1 33%;
      border: none;
      transition: border-bottom .4s ease-in-out;
      font-family: "BrownLLSub-Regular";
    }
    a:nth-of-type(2){
      display: flex;
      justify-content: center;
      flex: 0 1 33%;
      border: none ;
      transition: border-bottom .2s ease-in-out;
      font-family: "BrownLLSub-Regular";
    }
    a:last-child{
      display: flex;
      justify-content: center;
      flex: 0 1 33%;
      border: none;
      transition: border-bottom .2s ease-in-out;
      font-family: "BrownLLSub-Regular";
    }
  }
  .ui.bottom.attached.segment.active.tab{
    border: none !important;
  }
  .indicators > li{
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
  .react-slideshow-wrapper .images-wrap{
    align-items: center;
  }

`

export function MickeyTabs(props) {
    const wd = useWindowDimensions()
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

    const tabsRef = useRef([]);

    useEffect(() => {
        function setTabPosition() {
            const currentTab = tabsRef.current[activeTabIndex];
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
        }

        setTabPosition();
        window.addEventListener('resize', setTabPosition);

        return () => window.removeEventListener('resize', setTabPosition);
    }, [activeTabIndex]);

    return (
        <MickeyTabsWrapper className={props.className}>
            <div className='tabs-menu-inner-wrapper'>
                <div className='tabs-menu'>
                    {props.data?.map((tab, idx) => {
                        return (
                            wd.width <= 620 ? (
                                <div>
                                    <button
                                        className={idx === activeTabIndex ? 'tabs-menu-item active' : "tabs-menu-item"}
                                        key={idx}
                                        ref={(el) => (tabsRef.current[idx] = el)}
                                        onClick={() => setActiveTabIndex(idx)}
                                    >
                                        {tab.menuItem}
                                    </button>
                                    {
                                        idx === activeTabIndex &&
                                        <span
                                            className='tabs-menu-item-underline-mobile'
                                            style={{
                                                width: "100%",
                                                zIndex: 100,
                                            }}
                                        />
                                    }
                                </div>
                            ) :
                                (
                                    <>
                                        <button
                                            className={idx === activeTabIndex ? 'tabs-menu-item active' : "tabs-menu-item"}
                                            key={idx}
                                            ref={(el) => (tabsRef.current[idx] = el)}
                                            onClick={() => setActiveTabIndex(idx)}
                                        >
                                            {tab.menuItem}
                                        </button>
                                    </>
                                )
                        );
                    })}
                </div>
                <span className='tabs-menu-gray' />
                <span
                    className='tabs-menu-item-underline'
                    style={{
                        left: tabUnderlineLeft,
                        width: tabUnderlineWidth,
                    }}
                />
            </div>
            <div className='tabs-content'>
                <p>{props?.data[activeTabIndex]?.content()}</p>
            </div>
        </MickeyTabsWrapper>
    );
}

export default MickeyTabs